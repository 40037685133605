<template>
  <div class="social-space-header position-relative" :class="{ 'set-border': isManage }">
    <div class="cover">
      <img loading="lazy" v-image :src="resizeUpload(cover, '800w')" class="image" />
    </div>

    <div class="rest position-relative">
      <div class="d-flex justify-content-between top-part">
        <MatureTag v-if="isNsfw" size="md" class="mt-2" />
        <div v-if="isSocialSpaceOwner" class="edit-social-space d-flex justify-content-end">
          <ion-button class="button mr-2 mt-2" @click="editSocialSpace">
            <div class="edit-icon">
              <i class="ti-pencil" />
            </div>
            <div class="edit-text ml-1">Edit Social Space</div>
          </ion-button>

          <ion-button v-if="!isManage" class="button mr-2 mt-2" @click="manageSocialSpace">
            <div class="edit-icon">
              <i class="ti-pencil-alt" />
            </div>
            <div class="edit-text ml-1">Manage Social Space</div>
            <ion-badge v-if="get(counts, 'request-count')" class="mx-1" color="danger">{{
              get(counts, 'request-count')
            }}</ion-badge>
          </ion-button>
        </div>
      </div>

      <div class="name d-flex justify-content-center">
        <div><span v-if="socialspace?.privacy === 'U'">🔗&nbsp;</span>{{ name }}</div>
      </div>
      <div v-if="quote" class="quote">"{{ quote }}"</div>
      <div v-if="tags.length" class="tags mx-auto d-flex justify-content-center flex-wrap">
        <div
          v-for="(tag, index) of tags"
          :key="index"
          class="tag d-flex mt-1 justify-content-center align-items-center bg-white text-black"
          @click="openTagSearch(tag)"
        >
          #{{ truncateText(tag, 20) }}
        </div>
      </div>

      <ion-badge
        v-if="isAuthenticated && joinStatus.status !== 'requested' && status?.status !== 'member'"
        :color="privacyBadgeInfo?.color"
        class="mt-2"
        >{{ privacyBadgeInfo?.title }}</ion-badge
      >

      <div class="join-social-space d-flex justify-content-center text-center align-items-center">
        <ion-button
          v-if="
            isAuthenticated &&
            joinStatus.status !== 'requested' &&
            status?.status !== 'member' &&
            status?.status !== 'requested' &&
            get(loggedInUser, 'is_email_verified', true)
          "
          class="join-button"
          :class="isMobSmallScreen ? 'w-100' : ''"
          :disabled="isLoading"
          @click="joinSocial"
        >
          <ChLoading size="sm" v-if="isLoading" class="spinner" />
          <span v-else>Join This Space</span>
        </ion-button>
        <ion-button
          v-else-if="!get(loggedInUser, 'is_email_verified', true)"
          class="join-button"
          :class="isMobSmallScreen ? 'w-100' : ''"
          :disabled="isLoading"
          @click="openVerificationModal"
        >
          <ChLoading size="sm" v-if="isLoading" class="spinner" />
          <span v-else>Join This Space</span>
        </ion-button>
        <ion-button
          v-else-if="joinStatus.status === 'requested' || status?.status === 'requested'"
          class="join-button"
          style="pointer-events: none"
          :class="isMobSmallScreen ? 'w-100' : ''"
        >
          Pending Request
        </ion-button>
      </div>

      <div
        v-if="showBack"
        class="d-flex justify-content-center text-center align-items-center"
        style="margin-top: -5px"
      >
        <router-link
          :to="{ name: 'social-space-details', params: { slug: socialspace.slug } }"
          class="back clickable-item-hov"
          >Back to Space</router-link
        >
      </div>
      <div v-if="!isManage" class="actions mx-auto d-flex justify-content-center">
        <div class="d-flex w-100 top-row justify-content-center">
          <Reaction
            type="world"
            class="one-button"
            :reactions="reactions"
            :user-reaction="userReaction"
            @changed="(reaction) => reactionChanged(reaction.key, socialspace, reaction.isInstant)"
          >
            <ion-custom-button v-show="!userReaction" class="button">React</ion-custom-button>
            <ion-custom-button v-show="userReaction" class="button" color="secondary">{{
              userReactionText
            }}</ion-custom-button>
          </Reaction>
          <bookmark-picker
            v-if="isAuthenticated && socialspace?.id"
            :object-id="id"
            content-type="world"
            in-button
            class="bookmark"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="owner d-flex align-items-center">
          <span class="weight-500">Owned by:</span>
          <router-link
            :class="{
              'text-color-username':
                usernameColor(socialspace?.user) && !usernameColor(socialspace?.user).startsWith('#'),
              'bold-username': usernameColor(socialspace?.user),
            }"
            :style="
              !usernameColor(socialspace?.user)
                ? ''
                : usernameColor(socialspace?.user).startsWith('#')
                ? `color: ${usernameColor(socialspace?.user)} !important`
                : `background-image: ${usernameColor(socialspace?.user)} !important`
            "
            class="text mx-1"
            :to="{ name: 'profile', params: { username: username } }"
          >
            @{{ username }}
          </router-link>
          <ProfileBadge :badges="get(socialspace?.user.customize_profile, 'badges') || []" :show-small-badge="true" />
        </div>
        <div></div>
        <div>
          <ion-button
            v-if="isAuthenticated && !isSocialSpaceOwner"
            class="header-popover-button d-block"
            @click="spaceDropdownToggle"
            ><i class="ti-more-alt"
          /></ion-button>
        </div>
      </div>
    </div>

    <join-social-space
      :is-open="isFormOpen"
      :modal-title="modalTitle"
      :name="get(socialspace, 'name')"
      :socialspace-id="socialspace.id"
      @dismiss-modal="closeForm"
      @status="checkStatus"
    />
    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { worldReactionsNames } from '@/shared/statics/reactions';
import store from '@/shared/store';
import JoinSocialSpace from '@/shared/modals/JoinSocialSpace.vue';
import { socialSpaceStore } from '@/shared/pinia-store/socialspace';
import BookmarkPicker from '@/shared/components/BookmarkPicker.vue';
import { SocialSpace } from '@/shared/types/static-types';
import { createWorldCharUserRequest, leaveSocialSpace } from '@/shared/actions/worlds';
import MatureTag from '@/shared/components/MatureTag.vue';
import StoryWorldAndSocialSpacePopover from '@/shared/pages/story-worlds/popovers/StoryWorldAndSocialSpacePopover.vue';
import { toast, popovers } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { truncateText } from '@/shared/utils/string';
import Reaction from '@/shared/components/Reaction/index.vue';

const { user: loggedInUser, isAuthenticated } = authStore();

const { react } = socialSpaceStore();

const router = useRouter();
const route = useRoute();
const props = defineProps({
  socialspace: {
    type: Object,
    required: true,
  },
  status: {
    type: Object,
  },
  counts: {
    type: Object,
  },
  isManage: {
    type: Boolean,
  },
  showBack: {
    type: Boolean,
  },
});

const joinStatus = ref({ status: '', role: '' });
const isFormOpen = ref(false);
const emits = defineEmits(['updated', 'reaction']);
const status = toRef(props, 'status');
const socialspace = toRef(props, 'socialspace') as Ref<SocialSpace>;
const isLoading = ref(false);
const isNsfw = computed(() => socialspace.value.is_nsfw);

const verificationModal = ref(false);

const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const openTagSearch = (tagText: string) => {
  const hashText = '#';
  tagText = hashText.concat(tagText);
  router.push({ name: 'search', query: { term: tagText, tab: 'all' } });
};

const usernameColor = (user: any) => {
  return loggedInUser?.customize_profile?.username_color || '';
};

const closeForm = () => {
  isFormOpen.value = false;
};

const checkStatus = (status: { status: string; role: string }) => {
  joinStatus.value = status;
};

const editSocialSpace = () => {
  router.push({ name: 'edit-social-space', params: { id: socialspace?.value?.id } });
};

const manageSocialSpace = () => {
  router.push({ name: 'manage-social-space', params: { id: socialspace?.value?.id } });
};
const spaceDropdownToggle = async (ev: CustomEvent) => {
  const popover = await popovers.open(ev, StoryWorldAndSocialSpacePopover, {
    status: status.value,
    world: socialspace.value,
  });
  const { data } = await popover.onDidDismiss();
  if (get(data, 'value') === 'leave' && !isEmpty(data)) {
    leaveSocial();
  }
};

const joinSocial = async () => {
  if (socialspace?.value.privacy === 'O') {
    isLoading.value = true;

    await createWorldCharUserRequest({
      requestee_id: loggedInUser.value.id,
      requestee_type: 'user',
      world: socialspace.value.id,
      message: '',
    });
    emits('updated');

    isLoading.value = false;
    toast.show('You’re approved', 'nonative', 'success');
  } else {
    isFormOpen.value = true;
  }
};

const leaveSocial = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to leave <strong>${socialspace.value.name}! </strong>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await leaveSocialSpace(socialspace.value!.id as string);
      toast.show('Left social space successfully', 'nonative', 'success');
      router.push({ name: 'social-space' });
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const id = computed(() => {
  return socialspace.value!.id as string;
});

const username = computed(() => {
  return socialspace?.value?.user?.username || 'user';
});

const cover = computed(() => {
  return socialspace.value.cover_image;
});

const name = computed(() => {
  return socialspace.value.name;
});

const modalTitle = computed(() => {
  return 'Join ' + get(socialspace.value, 'name', '');
});

const quote = computed(() => {
  return socialspace.value.extra.quote;
});

const tags = computed(() => {
  return socialspace.value.tags || [];
});

const reactions = computed(() => {
  return socialspace?.value?.reaction_counts || [];
});

const userReaction = computed(() => {
  return socialspace?.value?.user_reaction;
});

const userReactionText = computed(() => {
  return worldReactionsNames[get(userReaction.value, 'reaction', '')];
});

const reactionChanged = async (reaction: string, social: SocialSpace, isInstant = false) => {
  let reactionResp: any;
  route.name === 'social-space-details'
    ? await react(reaction, isInstant)
    : (reactionResp = await store.dispatch('WorldsModule/react', { reaction, world: social, isInstant }));
  emits('reaction', { reactResp: reactionResp, social });
};

const isSocialSpaceOwner = computed(() => {
  try {
    return (
      socialspace.value.user?.username === loggedInUser.value.username ||
      joinStatus.value.role === 'leader' ||
      status.value?.role === 'leader'
    );
  } catch (error) {
    return false;
  }
});

const { width } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return width.value <= 500;
});

const privacyBadgeInfo = computed(() => {
  const privacyTitles = {
    O: { title: 'Open', color: 'primary' },
    P: { title: 'Closed', color: 'secondary' },
    U: { title: 'Unlisted', color: 'medium' },
  };

  return privacyTitles[socialspace.value.privacy as keyof typeof privacyTitles];
});
</script>

<style lang="sass" scoped>
.header-popover-button
  position: absolute
  right: 1rem
  bottom: 0.5rem
  height: 24px
  width: 48px
  --border-radius: 16px
  --background: #989aa2
  gap: 10px
  i
    font-size: 1.2rem
    color: white
.top-part
  margin-top: -20px
.chatroom-btn
  --border-radius: 15px
  height: 22px

.set-padding
  padding-top: 23px
.ios .set-padding
  padding-top: 11px !important
.back
  font-size: 14px
  color: #03a9f4
  font-weight: bold
.set-border
  border-bottom-left-radius: 0px !important
  border-bottom-right-radius: 0px !important
.dark .text
  color: #FFF
.dark .social-space-header .rest
  background-color: #17074C !important
  color: #FFF
.join-button
  --background: #00b4c5
  text-transform: none
  height: 50px
  --border-radius: 10px
  right: -31px
  bottom: -1px
  width: calc(100% - 375px)
  @media(max-width: 1184px)
    width: calc(100% - 698px) !important
  @media(max-width: 910px)
    width: calc(100% - 342px) !important
  @media(max-width: 710px)
    width: calc(100% - 242px) !important
  @media (max-width: 500px)
    width: calc(100% - 51px) !important
  @media(max-width: 340px)
    width: calc(100% - 38px) !important

.leave-button
  --background: #00b4c5
  text-transform: none
  height: 50px
  --border-radius: 10px
  right: -31px
  bottom: -1px
  width: calc(100% - 460px)
  @media(max-width:500px)
    bottom: -7px !important
    width: calc(100% - 203px) !important

  .spinner
    width: 15px
    height: 15px
.join-social-space
  margin-top: 20px
.actions
  flex-wrap: wrap
.bookmark
  ::v-deep
    ion-button
      height: 45px !important
      --border-radius: 10px
.top
  top: -8px
  margin-left: calc(100% - 24px)
  border-radius: 10px
  font-size: 12px
  width: 45px
.one-button
  max-width: 200px
  ::v-deep
    *
      text-transform: unset

@media(max-width: 360px)
  .bookmark
    ::v-deep
      ion-button
        height: 40px !important

@media(max-width: 500px)
  .actions
    grid-gap: 10px
    ::v-deep
      .one-button, .button-solid, .bookmark
        min-width: unset
        flex-grow: 1
        max-width: unset
        margin: 0 !important
  .social-space-header
    .edit-text
      display: none
.social-space-header
  color: black
  border-bottom-left-radius: 20px
  border-bottom-right-radius: 20px
  overflow: hidden
  .cover
    overflow: hidden
    max-height: 400px
    .image
      object-fit: cover
      width: 100%
      height: 100%
  .rest
    text-align: center
    background: #E6E6E6
    margin-top: -12px
    padding: 30px 24px 12px 24px
  .edit-social-space
    width: calc(100% - 24px)
    .button
      text-transform: unset
      font-size: 14px
      font-weight: 500
      --border-radius: 10px
      // color: #AE38E5 !important
  .owner
    margin-top: 24px
    text-align: start
    .weight-500
      font-weight: 700
  .actions
    margin-top: 2px
    width: 85%
    .one-button
      flex-grow: 1
      width: 100%
      ::v-deep
        .reacts, .like-btn
          width: 100%
        .button
          text-transform: unset !important
  .tags
    margin-top: 20px
    width: 80%
  .quote
    margin-top: 24px
    font-weight: 700
    font-size: 16px
    font-style: italic
  .name
    margin-top: 24px
    font-size: 24px
    font-weight: 700
  .back-space
    margin-top: 24px
    font-size: 24px
    font-weight: 700
  .tags
    .tag
      background-color: #1A1A1A
      border-radius: 5px
      color: #FFF
      height: 20px
      margin: 0 2.5px
      font-size: 10px
      padding: 0 8px
      cursor: pointer
.dark .owner
  color: white
</style>
