import { defineStore, storeToRefs } from 'pinia';
import { toast } from '@/shared/native';
import { createReactionOnWorld, deleteReactionOnWorld, updateReactionOnWorld } from '@/shared/actions/reactions';
import { UserReaction, ReactionWorldCounts, WorldsInfo, SocialSpace } from '@/shared/types/static-types';

import { getWorldsFromSlug } from '@/shared/actions/worlds';
import { imageStore } from '@/shared/pinia-store/images';

const isUserReactedSameReaction = (reaction: string, socialspace: SocialSpace, isInstant = false): boolean => {
  const { user_reaction } = socialspace;
  return !!(user_reaction && user_reaction.id && (user_reaction.reaction === reaction || isInstant));
};

const isUserReactedAnotherReaction = (reaction: string, socialspace: SocialSpace): boolean => {
  const { user_reaction } = socialspace;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction !== reaction);
};

const sendReactionToBackend = async (reaction: string, socialspace: SocialSpace, isInstant: boolean = false) => {
  const target = 'socialspace';
  const { user_reaction, id: socialspaceId = '' } = socialspace;

  // delete
  if (isUserReactedSameReaction(reaction, socialspace, isInstant)) {
    return await deleteReactionOnWorld({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, socialspace)) {
    return await updateReactionOnWorld({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnWorld({ objectId: socialspaceId, reaction, target });
  return data;
};

const updateReactionsDataForWorld = (
  reaction: string,
  socialspace: SocialSpace,
  newReaction: UserReaction
): Partial<SocialSpace> => {
  const socialspaceData = cloneDeep(socialspace);
  socialspaceData.reaction_counts = socialspaceData.reaction_counts || { total_count: 0 };
  const totalCount = socialspaceData.reaction_counts.total_count;
  let userReaction = socialspaceData.user_reaction || ({} as UserReaction);
  const prevUserReaction = socialspaceData.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (socialspaceData.user_reaction as UserReaction) {
    socialspaceData.reaction_counts[prevUserReaction.reaction as keyof ReactionWorldCounts]--;
    if (newReaction.reaction in socialspaceData.reaction_counts) {
      socialspaceData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      socialspaceData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    socialspaceData.reaction_counts!.total_count = totalCount + 1;
    if (!socialspaceData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts]) {
      //  @ts-ignore
      socialspaceData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] = 1;
    } else {
      //  @ts-ignore
      socialspaceData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReaction(reaction, socialspaceData)) {
    socialspaceData.user_reaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, socialspaceData) && !newReaction) {
    socialspaceData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    socialspaceData.user_reaction = undefined;
  } else {
    socialspaceData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: socialspaceData.user_reaction,
    reaction_counts: socialspaceData.reaction_counts,
  };
  return out;
};

export const newTextKey = (socialspace: SocialSpace) => {
  const info = socialspace;
  const keys = Object.keys(info);
  const maxKeyNumber =
    keys
      .filter((key) => key.includes('text'))
      .map((key) => Number(key.replaceAll('text', '')))
      .sort((a, b) => a - b)
      .pop() || 0;
  const newKey = `text${maxKeyNumber + 1}`;
  return newKey;
};

export interface ISocialSpaceStore {
  socialspace: SocialSpace | null;
  isLoading: boolean;
  isSectionsCollapsed: SectionsCollapsed;
}

export enum SectionsCollapsed {
  true = 1,
  false = 0,
  noCare = -1,
}

const useSocialSpace = defineStore('socialspace', {
  state: (): ISocialSpaceStore => ({
    socialspace: null,
    isLoading: false,
    isSectionsCollapsed: 0,
  }),
  actions: {
    async loadSocialSpaceBySlug(slug: string) {
      const { reset: resetImages, setImageCollections } = imageStore();
      resetImages();
      try {
        this.isLoading = true;
        const socialSpace = await getWorldsFromSlug(slug);
        this.socialspace = socialSpace;
        if (socialSpace.visual_collections_page?.results) {
          setImageCollections(socialSpace.visual_collections_page.results);
        }
      } catch (error) {
        toast.show('Error loading Social Space', 'nonative', 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    collapseSections(value: SectionsCollapsed) {
      this.isSectionsCollapsed = value;
    },
    async react(reaction: string, isInstant = false) {
      if (!this.socialspace) return;
      const newReaction = await sendReactionToBackend(reaction, this.socialspace, isInstant);
      const updatedReactionsData = updateReactionsDataForWorld(reaction, this.socialspace, newReaction);
      this.socialspace = {
        ...this.socialspace,
        ...updatedReactionsData,
      };
      return { newReaction, updatedReactionsData };
    },
  },
  getters: {
    socialSpace(): SocialSpace | null {
      return this.socialspace;
    },
    loading(): boolean {
      return this.isLoading;
    },
    sectionsCollapsed(): SectionsCollapsed {
      return this.isSectionsCollapsed;
    },
  },
});

export const socialSpaceStore = () => {
  const store = useSocialSpace();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
